.techniques-table {
    display: flex;
  justify-content: center;
  flex-flow: column wrap;
  width: 100%;
  height: 100%;
    }

.techniques-table-div{
    display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.techniques-card{
    margin: 20px;
  background: #fff;
  max-height: 300px;
  max-width: 300px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
