.App {
  
}

p{
  margin-bottom: 8px;
}

.navbar-nav {
	overflow-y: auto;
}

.about-page-content .content{
  color: rgba(0, 0, 0, 0.8);
}

.before-start-page-content .content{
  color: rgba(0, 0, 0, 0.8);
}

.technique-carousel .carousel-inner{
  max-height: 23rem;
  margin-bottom: 20px;
}

.technique-no-images-typography{
  margin-bottom: 20px;
}

.mobile-body {
  margin-top: 80px;
}

.pc-body {
  margin-top: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main-body-container{
  margin-top: 5rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HeaderButtonText {
color: azure;
}

.HeaderButton{
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.main-page-content .main-page-text{
  color: rgba(0, 0, 0, 1);
  font-size: large;
  font-family: "Raleway", "Helvetica", "Arial", sans-serif;
  text-align: center;
}

.main-page-row{
  margin-top: 3rem;
}

.mobile-main-page-last-row{
  margin-bottom: 3rem;
}

.main-page-image{
  max-width: 100%;
  height: auto;
}

.main-page-content .main-page-list{
  color: rgba(0, 0, 0, 1);
  font-size: large;
  font-family: "Raleway", "Helvetica", "Arial", sans-serif;
}

.main-page-content .main-page-list ul {
  max-width: 400px;
  margin: 0 auto;
  list-style-type: none;
  margin: 0;
  font-family: sans-serif;
}
.main-page-content .main-page-list ul li {
  padding: 0 0 20px 50px;
  position: relative;
  margin: 0;
}
.main-page-content .main-page-list ul li:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\2022";
  border: 2px solid rgb(133, 218, 224);
  color: rgb(133, 218, 224);
  border-radius: 50%;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  text-align: center;
  line-height: 1rem;
  background: rgb(133, 218, 224);
}
.main-page-content .main-page-list ul li:before {
  position: absolute;
  left: 0.45rem;
  top: 0rem;
  content: "";
  height: 100%;
  width: 0;
  border-left: 2px solid rgb(133, 218, 224);
}
.main-page-content .main-page-list ul li:last-of-type:before {
  border: none;
}