.mobile-body .technique-info-content .accordion-body {
    padding: 1rem 0.25rem;
}

.mobile-body .technique-info-content .accordion-button {
    padding: 1rem 0.25rem;
}

.technique-info-content .short-description-text{
    color: rgba(0, 0, 0, 0.8);
}

.technique-info-content .accordion-body{
    color: rgba(0, 0, 0, 0.8);
}

.technique-info-content .accordion-button:not(.collapsed){
    background-color : rgba(255, 255, 255, 0);
}

.technique-info-content .accordion-button:focus{
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, 0);
} 