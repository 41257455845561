.offcanvas {
  width: 75%;
}

.mobile-navbar{
  overflow: auto;
}

.navbar-do-together-button{
  float:right;
}